.custom-styles {
  --ReactInputVerificationCode-itemWidth: 4rem;
  --ReactInputVerificationCode-itemHeight: 4rem;
  display: flex;
  justify-content: center;
}

.custom-styles .ReactInputVerificationCode__item {
  font-weight: 300;
}

.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: 0 0 0 1pt #229a16;
}
